var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "search-attr" }, [
        _c(
          "div",
          [
            _c("a-input", {
              staticStyle: { width: "150px" },
              attrs: { size: "small", placeholder: "请输入昵称" },
              model: {
                value: _vm.params.userName,
                callback: function($$v) {
                  _vm.$set(_vm.params, "userName", $$v)
                },
                expression: "params.userName"
              }
            }),
            _c(
              "a-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { size: "small" },
                on: { click: _vm.search }
              },
              [_vm._v("搜索")]
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "a-button",
              {
                attrs: { size: "small" },
                on: {
                  click: function($event) {
                    return _vm.openModal({})
                  }
                }
              },
              [_vm._v("新增")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("a-table", {
            attrs: {
              pagination: _vm.pagination,
              loading: _vm.loading,
              columns: _vm.columns,
              "data-source": _vm.data,
              rowKey: function(record, n) {
                return n
              }
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "Num",
                fn: function(row) {
                  return _c("div", {}, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.openPage(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(row.releaseProductNum))]
                    )
                  ])
                }
              },
              {
                key: "set",
                fn: function(row) {
                  return _c("div", {}, [
                    _c(
                      "a",
                      {
                        staticClass: "map-margin",
                        on: {
                          click: function($event) {
                            return _vm.openModal(row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "map-margin",
                        on: {
                          click: function($event) {
                            return _vm.remove(row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ])
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: _vm.IO === 1 ? "编辑" : "新增" },
          on: { ok: _vm.hideModal, cancel: _vm.close },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "ruleForm",
                attrs: { model: _vm.forms, rules: _vm.rules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "选择用户", prop: "userId" } },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "100%", "margin-bottom": "20px" },
                      attrs: {
                        placeholder: "选择用户",
                        "filter-option": _vm.untils.filterOption,
                        showSearch: ""
                      },
                      on: { search: _vm.getUsers },
                      model: {
                        value: _vm.forms.userId,
                        callback: function($$v) {
                          _vm.$set(_vm.forms, "userId", $$v)
                        },
                        expression: "forms.userId"
                      }
                    },
                    _vm._l(_vm.user_list, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.id, attrs: { value: item.id } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  "max-width": "20px",
                                  "max-height": "20px",
                                  "border-radius": "50%",
                                  "margin-right": "10px"
                                },
                                attrs: { src: item.userAvatar, alt: "" }
                              }),
                              _c("span", { attrs: { text: item.userName } }, [
                                _vm._v(_vm._s(item.userName))
                              ])
                            ]
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "商品数量", prop: "releaseProductNum" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入商品数量" },
                    model: {
                      value: _vm.forms.releaseProductNum,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "releaseProductNum", $$v)
                      },
                      expression: "forms.releaseProductNum"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "排序", prop: "sort" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入排序号" },
                    model: {
                      value: _vm.forms.sort,
                      callback: function($$v) {
                        _vm.$set(_vm.forms, "sort", $$v)
                      },
                      expression: "forms.sort"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }