<template>
    <div class="content">
        <div class="search-attr">
            <div>
                <a-input v-model="params.userName" size='small' style="width:150px;" placeholder='请输入昵称'></a-input>
                <a-button @click="search" size='small' style="margin-left:10px;">搜索</a-button>
            </div>
            <div>
                <a-button @click="openModal({})" size='small'>新增</a-button>
            </div>
        </div>
        <div class="table-content" >
            <a-table @change="changePage" :pagination='pagination' :loading='loading' :columns="columns" :data-source="data" :rowKey="(record,n)=>n">
                 <div slot="Num" slot-scope="row">
                    <a @click="openPage(row)">{{row.releaseProductNum}}</a>
                </div>

                <div slot='set' slot-scope="row">
                    <a @click="openModal(row)" class="map-margin">编辑</a>
                    <a class="map-margin" @click="remove(row)">删除</a>
                </div>
            </a-table>
        </div>

        <a-modal @ok='hideModal' @cancel='close' v-model="visible" :title="IO === 1 ? '编辑' : '新增'">
            <a-form-model v-bind="layout" :model='forms' :rules='rules' ref="ruleForm">
                <a-form-model-item label='选择用户' prop='userId'>
                    <a-select  @search='getUsers' v-model="forms.userId" placeholder='选择用户' :filter-option="untils.filterOption" showSearch style="width:100%;margin-bottom:20px;">
                        <a-select-option v-for="item of user_list" :key="item.id" :value='item.id'>
                            <div style="display:flex;align-items:center;">
                                <img style="max-width:20px;max-height:20px;border-radius:50%;margin-right:10px;" :src="item.userAvatar" alt="">
                                <span :text='item.userName'>{{item.userName}}</span>
                            </div>
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label='商品数量' prop='releaseProductNum'>
                    <a-input placeholder='请输入商品数量' v-model="forms.releaseProductNum"></a-input>
                </a-form-model-item>
                <a-form-model-item label='排序' prop='sort'>
                    <a-input placeholder='请输入排序号' v-model="forms.sort"></a-input>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
export default {
    data(){
        return {
            columns:[
                {title:'用户名',dataIndex:'userName'},
                {title:'头像',dataIndex:'userAvatar',
                    customRender:(row)=>{
                        return <img style='max-width:50px;max-height:50px;' src={row} />
                    }
                },
                {title:'发布商品数',scopedSlots: { customRender: 'Num' },},
                {title:'操作',align:'center',scopedSlots: { customRender: 'set' },},
            ],
            data:[],
            layout:{
                labelCol: { span: 4 },//标签占5份  共24份
                wrapperCol: { span:20 },
            },
             rules:{
                userId:[{ required: true, message: '请选择用户', trigger: 'change' }],
                releaseProductNum:[{ required: true, message: '请输入', trigger: 'change' }],
                sort:[{ required: true, message: '请输入', trigger: 'blur' }],
            },
            forms:{},
            visible:false,
            params:{pageNum:1,pageSize:10,userName:'',userPhone:'',},
            pagination:{
                total:0,
                current:1,
                showTotal:(total)=>`共${total}条`,
            },
            loading:false,
            IO:0,
            user_list:[],
        }
    },
    created(){
        this.getData(this.params)
        this.getUsers()
    },
    methods:{
        async getData(params){
            this.loading = true
            const res = await this.axios('/dq_admin/recommendUser/list',{params:params})
            this.loading = false
           
            let list = res.data.records;
            this.data = list;
            this.pagination.total = res.data.total;

        },
        search(){
            this.params.pageNum = 1
            this.getData(this.params)
        },
        
        changePage(page){
            const {current,pageSize} = page
            this.pagination.current = current
            this.params.pageNum = current
            this.params.limit = pageSize
            this.getData(this.params)
        },
        openPage(row){
            const routeData = this.$router.resolve({
            path: "/cms/currencymannage/goods",
            query: {
                userId:row.userId||100
            }
            });
            window.open(routeData.href, '_blank');
        },
        async remove(row){
            const {id} = row
            const res = await this.axios.post('/dq_admin/recommendUser/del',{id})
            if(res.status == 200){
                const {message} = res
                this.$message.success(message)
                this.getData(this.params)
            }
        },
        openModal(row){
            const {id} = row || {id:''}
            if(id){
                this.IO = 1
                const proxyObj = JSON.parse(JSON.stringify(row))
                this.forms = proxyObj
            }else{
                this.IO = 0
                this.forms = {}
                
            }
            this.visible = true
        },

        async getUsers(inner){
            const res = await this.axios('/dq_admin/user/list',{params:{userName:inner,limit:20}})
            if(res.status == 200){
                const {data} = res
                this.user_list = data
            }
        },
        close(){
            this.resetForms()
            this.visible = false
        },
        hideModal(){
            this.$refs.ruleForm.validate(valid => {
                if(valid){
                    this.submit(this.forms)
                }else{
                    return false
                }
            });
        },
        async submit(row){
            const res = await this.axios.post('/dq_admin/recommendUser/addOrEdit',row)
            if(res.status == 200){
                const {message} = res
                this.$message.success(message)
                this.getData(this.params)
                this.close()
            }
        },
        resetForms(){
            this.forms = {}
            this.$refs.ruleForm.resetFields()
        },
    }
}
</script>

<style lang="scss" scoped>
.search-attr{display: flex;justify-content: space-between;padding-bottom:20px;}
.content{display: flex;flex-direction: column;
    .table-content{
        flex:1;
        overflow-y: auto;
    }
}
.take-box{width:100%;max-height:700px;}
</style>